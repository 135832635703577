<div class="wrapper container" style="margin-top:5em;">
  <!-- <img src="https://d2slcw3kip6qmk.cloudfront.net/marketing/press/images/template-gallery/banner-ad2-728x90.jpeg"
  alt="photo of buruju.com" style="width: 100%;height: auto; display: block;
margin-left: auto;
margin-right: auto;padding:0px 0px 5px 0px;"> -->
    <main class="profile-page">
      <section class="section section-lg pt-lg-0 section-contact-us">
        <div class="container">
          <div class="row justify-content-center mt--300">
            <div class="col-lg-4">
              <div class="card bg-gradient-secondary shadow">
                <div class="card-body p-lg-5">
                  <h4 class="mb-1">You can reach us on:</h4>
                  <!-- <p class="mt-0"><b>Mobile:</b> +91123456789</p> -->
                  <p class="mt-0"><b>Email Id:</b> editor@buruju.com</p>
                  <br>
                      <span><a class="facebook" style="color:#3b5998;font-size:2.5em;padding:10px;" href="#"><i class="fa fa-facebook"></i></a></span>
                      <span><a class="twitter" style="color:#00aced;font-size:2.5em;padding:10px;" href="#"><i class="fa fa-twitter"></i></a></span>
                </div>
                </div>
            </div>
            <div class="col-lg-8">
              <div class="card bg-gradient-secondary shadow">
                <div class="card-body p-lg-5">
                  <h4 class="mb-1">Want to contact us?</h4>
                  <p class="mt-0">Drop us a message we will get back to you.</p>
                  <div class="form-group mt-5">
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><mat-icon>person</mat-icon></span>
                      </div>
                      <input class="form-control" placeholder="Your name*" [(ngModel)]="username" type="text" required />
                    </div>
                  </div>
                  <div class="form-group" >
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><mat-icon>email</mat-icon></span>
                      </div>
                      <input class="form-control" placeholder="Email address*" [(ngModel)]="email" type="email" required />
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <textarea class="form-control form-control-alternative" [(ngModel)]="textbox" name="name" rows="4" cols="80" placeholder="Type a message... *" required></textarea>
                  </div>
                  <div>
                    <button type="button" (click)="formsubmit()" class="btn btn-default btn-round btn-block btn-lg">Send Message</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </main>
</div>
