<div id="header" class="wrapper">
    <div class="filter"></div>
    <div class="section profile-content">
        <div class="container">
            <div style="margin-top:5em;"></div>
            <br />
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="12px" fxLayoutAlign="flex-start">
                <mat-card *ngIf="loaddata" style="padding:5px;" fxFlex="0 1 calc(75% - 0px)"
                    fxFlex.lt-md="0 1 calc(75% - 32px)" fxFlex.lt-sm="100%">
                    <div id="bannerFull" *ngIf="NewsServiceService.newsPageAds">
                        <img src="https://www.yogiapproved.com/wp-content/uploads/2017/11/YOGA-STRONG-BANNER-900X250.jpg"
                            alt="photo of buruju.com" style="width: 100%;height: auto; display: block;
                    margin-left: auto;
                    margin-right: auto;padding:0px 0px 20px 0px;">
                    </div>
                    <div id="bannerMobile" *ngIf="NewsServiceService.newsPageAds">
                        <img  src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
                        alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
                      margin-left: auto;
                      margin-right: auto;">
                      </div>
                    <mat-divider [inset]="true"></mat-divider>
                    <div class="search-hero">
                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="&#61442;  Search in telugu">
                      </div>
                    <div *ngFor="let i of searchdata | filter:searchText">
                        <div fxLayout="row" fxLayoutAlign="flex-start" style="padding:10px 5px 0px 2px;">
                            <div style="cursor: pointer;" [routerLink]="['/news', i.article_url]">
                                <img src="{{i.image}}" alt="{{i.articletitle}}" width="110" height="110">
                            </div>
                            <div style="margin-left:5px">
                                <a [routerLink]="['/news', i.article_url]"
                                    style="color:black;text-decoration: none;font-size: 20px;"><span style="margin-bottom: 5px;"><b>{{i.articletitle}}</b></span><br><br>
                                <div *ngIf="i.articlecontent.length<=120" [routerLink]="['/news', i.article_url]"
                                    style="color:rgb(73, 70, 70);text-decoration: none;font-size: 14px;">{{i.articlecontent}}
                                </div>
                                <div *ngIf="i.articlecontent.length>120" [routerLink]="['/news', i.article_url]"
                                    style="color:rgb(73, 70, 70);text-decoration: none;font-size: 14px;">{{i.articlecontent |
                                    slice:0:120}}...</div></a>
                                <div class="icon-text" style="font-size: 12px;position: relative;">
                                    <span class="material-icons" style="font-size: 17px;">person</span><span
                                        style="padding:2px;">{{i.author}}</span>
                                    <span class="material-icons" style="font-size: 17px;">date_range</span>
                                    <span style="padding:8px;">{{i.date}}</span>
                                </div>
                            </div>
                        </div>
                        <mat-divider [inset]="true"></mat-divider>
                    </div>
                </mat-card>
                <mat-card class="example-card card card-stats" fxFlex="0 1 calc(25% - 12px)"
                    fxFlex.lt-md="0 1 calc(25% - 12px)" fxFlex.lt-sm="100%">
                    <img mat-card-image
                        src="https://www.disruptivestatic.com/wp-content/uploads/2015/03/AG-ever-336x280-300x250.png"
                        alt="photo of buruju.com">
                    <mat-card-content>
                        <h3>Recent Posts:</h3>
                        <mat-divider [inset]="true"></mat-divider>
                        <div *ngFor="let i of recentdata">
                            <div fxLayout="row" fxLayoutAlign="flex-start" style="padding:5px 5px 0px 2px;">
                                <div>
                                    <img src="{{i.image}}" alt="{{i.articletitle}}" width="70" height="70">
                                </div>
                                <div style="margin-left:5px">
                                    <a [routerLink]="['/news', i.article_url]"
                                        style="color:black;text-decoration: none;font-size: 16px;">{{i.articletitle}}</a>
                                    <div class="icon-text"
                                        style="font-size: 14px;">
                                        <span class="material-icons" style="font-size: 17px;"></span><span
                                            style="padding:5px;" class="label label-info">{{getTagName(i.category)}}</span>

                                    </div>
                                </div>
                            </div>
                            <mat-divider [inset]="true"></mat-divider>
                          </div>
                    </mat-card-content>
                    <span style="display: flex;
                    align-items: center;margin-top:15px;">
                        <img mat-card-image
                            src="https://www.disruptivestatic.com/wp-content/uploads/2015/03/AG-ever-336x280-300x250.png"
                            alt="photo of buruju.com">
                    </span>
                </mat-card>
            </div>
        </div>
    </div>

</div>
<div>
    <!--160x600 images-->
    <div id="FloatAdx-left">
        <img src="https://i.gifer.com/G80Q.gif">
    </div>
    <div id="FloatAdx-right">
        <img src="https://www.animatedbanner.com/images/samples/sky/apnea160x600.gif">
    </div>
</div>