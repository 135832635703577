<nav class="navbar navbar-expand-lg fixed-top navbar-transparent" color-on-scroll="100">
    <div class="container" style="max-height:60px !important;flex-wrap: nowrap !important;margin-top: 5px;">
        <div class="navbar-translate">
            <a class="navbar-brand" href="https://buruju.com"> <img src="https://buruju.com/images/buruju-logo1.png"
                    style="height: 60px;" alt="">
                <!-- <span style="color:black">Buruju
            </span> -->
            </a>
            <!-- </a><b>{{test | date: 'dd-MMM-yyyy'}}</b> -->
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse"
                data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="navbar-collapse" id="navbarToggler">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngIf="!isDocumentation()" style="margin-top:5px;">
                    <a class="nav-link" rel="tooltip" title="Follow us on Twitter" data-placement="bottom"
                        href="https://buruju.com/#/home">
                        <span style="color:black;">
                            <p style="font-size:12px;">News</p>
                            <!-- <p class="d-lg-none" style="margin-left:10px;">About Us</p> -->
                        </span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!isDocumentation()" style="margin-top:5px;">
                    <a class="nav-link" rel="tooltip" title="Follow us on Twitter" data-placement="bottom"
                        href="https://buruju.com/#/about">
                        <span style="color:black;">
                            <p style="font-size:12px;">About Us</p>
                            <!-- <p class="d-lg-none" style="margin-left:10px;">About Us</p> -->
                        </span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!isDocumentation()" style="margin-top:5px;">
                    <a class="nav-link" rel="tooltip" title="Follow us on Twitter" data-placement="bottom"
                        href="https://buruju.com/#/contact">
                        <span style="color:black;">
                            <p style="font-size:12px;">Contact Us</p>
                            <!-- <p class="d-lg-none" style="margin-left:10px;">Contact Us</p> -->
                        </span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!isDocumentation()" style="margin-top:5px;">
                    <a class="nav-link" rel="tooltip" title="Follow us on Twitter" data-placement="bottom"
                        href="https://buruju.com/#/searchburuju">
                        <span style="color:black;">
                            <p style="font-size:12px;">Search</p>
                            <!-- <p class="d-lg-none" style="margin-left:10px;">Contact Us</p> -->
                        </span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="!isDocumentation()">
                    <a class="nav-link" rel="tooltip" title="Follow us on Twitter" data-placement="bottom"
                        href="https://twitter.com/BurujuNews" target="_blank">
                        <span style="color:#00acee;">
                            <i style="font-size:2.2em;" class="fa fa-twitter-square"></i>
                            <p class="d-lg-none" style="margin-left:10px;">Twitter</p>
                        </span>
                    </a>
                </li>
                <!-- <li class="nav-item" *ngIf="!isDocumentation()">
                    <a class="nav-link" rel="tooltip" title="Like us on Facebook" data-placement="bottom"
                        href="https://www.facebook.com/BurujuNews" target="_blank">
                        <span style="color:#3b5998">
                            <i style="font-size:2.2em;" class="fa fa-facebook-square"></i>
                            <p class="d-lg-none" style="margin-left:10px;">Facebook</p>
                        </span>
                    </a>
                </li> -->
                <!-- <li class="nav-item" *ngIf="!isDocumentation()">
                    <a class="nav-link" rel="tooltip" [routerLink]="['/']">
                        <span style="color:black">
                            <i style="font-size:2.2em;" class="fa fa-search"></i>
                            <p class="d-lg-none" style="margin-left:10px;">Search</p>
                        </span>
                    </a>
                </li> -->
                <!-- <li class="nav-item" *ngIf="!isDocumentation()">
                    <a class="nav-link" rel="tooltip" title="Like us on Facebook" data-placement="bottom"
                        href="https://twitter.com/BurujuNews" target="_blank">
                        <span style="color:#3b5998">
                            <i style="font-size:2.2em;" class="fa fa-bell"></i>
                            <p class="d-lg-none" style="margin-left:10px;">Notification</p>
                        </span>
                    </a>
                </li> -->
                <!-- <li class="nav-item" *ngIf="!isDocumentation()">
                    <a class="nav-link" rel="tooltip" title="Like us on Facebook" data-placement="bottom"
                        href="https://twitter.com/BurujuNews" target="_blank">
                        <span style="color:black">
                            <i style="font-size:2.2em;" class="fa fa-envelope"></i>
                            <p class="d-lg-none" style="margin-left:10px;">Subscribe</p>
                        </span>
                    </a>
                </li> -->
                <!-- <li class="nav-item">
                    <span style="color:black">
                        <button class="btn btn-danger btn-round">Subscribe</button>
                    </span>
                </li> -->
                <!-- <li class="nav-item" *ngIf="!isHome()">
                    <span style="color:black"> 
                    <a [routerLink]="['/home']" *ngIf="!isDocumentation()" class="nav-link"><i class="nc-icon nc-layout-11"></i>Components</a>
                    <a [routerLink]="['/home']" *ngIf="isDocumentation()" class="nav-link">Back to Kit</a>
                        </span>
                </li> -->
            </ul>
        </div>
    </div>
</nav>