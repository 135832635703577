<div class="wrapper container" style="margin-top:4.5em;">
  <div id="bannerFull" *ngIf="NewsServiceService.newsPageAds">
  <img src="https://d2slcw3kip6qmk.cloudfront.net/marketing/press/images/template-gallery/banner-ad2-728x90.jpeg"
  alt="photo of buruju.com" style="width: 100%;height: auto; display: block;
margin-left: auto;
margin-right: auto;padding:0px 0px 5px 0px;">
</div>
<div id="bannerMobile" *ngIf="NewsServiceService.newsPageAds">
  <img  src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
  alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
margin-left: auto;
margin-right: auto;">
</div>
    <main class="profile-page">
        <section class="section">
          <div class="container">
            <!-- <h1 style="text-align: left;"><b>ABOUT BURUJU.COM</b></h1> -->
            <div class="card card-profile shadow" style="margin-top:0px;">
              <div class="px-4">
                <div class="row justify-content-center">
                  <div class="col-lg-3 order-lg-2">
                    <div class="card-profile-image">
                      <a href="emailto:editor@buruju.com">
                        <img src="https://buruju.com/images/buruju-logo1.png" style="height:80px;">
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                    <div class="card-profile-actions py-4 mt-lg-0">
                      <!-- <a href="https://buruju.com/#/contact" class="btn btn-sm btn-info mr-4">Contact</a> -->
                      <!-- <a href="javascript:void(0)" class="btn btn-sm btn-default float-right">Message</a> -->
                    </div>
                  </div>
                  <div class="col-lg-4 order-lg-1">

                  </div>
                </div>
                <div class="text-center mt-1">
                  <h3>Pilla Sai Kumar
                    <!-- <span class="font-weight-light">, 27</span> -->
                  </h3>
                  <!-- <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>Telangana, Indoa</div> -->
                  <div class="h6 mt-1"><i class="ni business_briefcase-24 mr-2"></i>Cheif Editor - Buruju.com</div>
                  <div><i class="ni education_hat mr-2"></i>Former Prinicipal correspondent - EENADU</div>
                  <br>
                  <p>Pilla Sai Kumar worked as a Prinicipal correspondent with the EENADU, telugu daily for a period of 36 years. During this long time, he worked in various places like Saravakota, Vizianagaram, Visakhapatnam, Eluru, Sangareddy, Nalgonda and Hyderabad. He has written several news items and articles on various topics. Any suggestion or feedback kindly write us at:</p>
                  <a href="mailto:editor@buruju.com" style="font-weight: bold;">editor@buruju.com</a>
                </div>
                <div class="mt-2 py-5 border-top text-center">
                  <div class="row justify-content-center">
                    <div class="col-lg-9">
                      <p>BURUJU.com, the online telugu portal brings you weekly news and views mainly focusing on Social, Financial and Historical events of Andhra Pradesh and Telangana states from Hyderabad, India.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- <section class="section section-lg">
          <div class="container">
            <div class="card card-profile shadow" style="margin-top:0px;">
            <div class="row row-grid justify-content-center">
              <div class="col-lg-8 text-center">
                <p class="lead">BURUJU.com, the online telugu portl brings you weekly news and views mainly focusing on social, financial and Historical events of Andhra Pradesh and Telangana states.</p>
                <div class="btn-wrapper">
                  <a href="" class="btn btn-primary mb-3 mb-sm-0">Download Angular</a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section> -->
      </main>
</div>