import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsServiceService } from '../../news-service.service';
import { News } from '../../news';
// import * as postscribe from 'postscribe';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
    article_url;
    private sub: any;
    resdata;
    loaddata = false;
    recentdata = [];
    loadrecentdata = false;
    constructor(public NewsServiceService: NewsServiceService, private route: ActivatedRoute) {}

    ngOnInit() {
        // this.sub = this.route.params.subscribe(params => {
        //     this.id = +params['articlename']; // (+) converts string 'id' to a number
        //     console.log("this.id"+this.id);
        //     // In a real app: dispatch action to load the details here.
        //  });
        this.route.params.subscribe(params => {
            this.article_url = params['id'];
            // console.log("this.id"+this.article_url);
            this.NewsServiceService.getNewsArticle(this.article_url).subscribe((data: News[] ) =>{
                var newdata = JSON.stringify(data);
                this.resdata = data[0];
                this.loaddata = true;
                // console.log(newdata);
                var url_val = "https://buruju.com/news/"+this.resdata.article_url;
                this.NewsServiceService.updateDescription(this.resdata.articletitle,this.resdata.articletitle,this.resdata.author,url_val,this.resdata.category, this.resdata.image);
            },error => {console.log('Something went wrong!', error)});
        },error => {console.log('Something went wrong!', error)});
        this.NewsServiceService.getRecentArticles().subscribe((data: News[] ) =>{
            var newdata = JSON.stringify(data);
            this.recentdata = data;
            this.loadrecentdata = true;
            // console.log(newdata);
        });
    }
    getTagName(val){
        return this.NewsServiceService.getTagName(val);
    }
    // ngAfterViewInit() {
    //     postscribe('#bannerIdLeft', `<script type="text/javascript" language="javascript">
    //     var aax_size='160x600';
    //     var aax_pubname = 'buruju-21';
    //     var aax_src='302';
    //   </script>
    //   <script type="text/javascript" language="javascript" src="http://c.amazon-adsystem.com/aax2/assoc.js"></script>`);
    //     }
    ngAfterViewInit() {
        setTimeout(()=>{
         try {
          (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
         } catch (e) {}
        },3000);
       }
}
