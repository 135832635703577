<div id="header" style="padding:0px;margin-top: 5em;">
    <div class="container">
        <div class="section" id="carousel" style="padding:2px;">
            <div class="row" style="padding:5px;">
                <mat-card class="col-md-4 ml-auto" style="margin:5px 0px;border-bottom: 2px solid #676cba;">
                    <div class="widget" *ngIf="weatherData" fxLayout="column">
                        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center space-between">
                            <div fxFlex="40%">
                                <div class="date">
                                    <!-- {{todaydate| date}} -->
                                    {{weatherData.location.localtime}}
                                </div>
                                <div class="city">
                                    <!-- {{weatherData.name}} -->
                                    <div *ngIf="weatherData.location.name.length>12" matTooltip="{{weatherData.location.name}}">
                                        {{weatherData.location.name| slice: 0:12}}...
                                </div>
                                <div *ngIf="weatherData.location.name.length<=12" matTooltip="{{weatherData.location.name}}">
                                    {{weatherData.location.name}}
                                </div>
                                </div>
                                <div class="info">
                                    <!-- {{weatherData.weather[0].description | titlecase}} -->
                                    {{weatherData.current.condition.text | titlecase}}
                                </div>
                            </div>
                            <div fxFlex="20%">
                                <img src="http://{{weatherData.current.condition.icon}}" alt="http://{{weatherData.current.condition.icon}}" width="80" />
                                <!-- <img src="http://openweathermap.org/img/wn/{{weatherData.weather[0].icon}}.png" alt="" width="80" /> -->
                            </div>
                            <div fxFlex="40%">
                                <div style=" font-size: 61px;
                                    color: rgba(0,0,0,0.9);
                                    font-weight: 500;margin-top: 24px;margin-left: 22px;">{{weatherData.current.temp_c |
                                    number :
                                    '1.0-0'}}&deg;</div>
                            </div>
                        </div>
                        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between space-between">
                            <div>
                                <span style="font-size: 13px;">Humidity <b>{{weatherData.current.humidity | number :
                                        '1.0-2'}}&deg;</b></span>
                            </div>
                            <div>
                                <span style="font-size: 13px;">Wind Speed <b>{{weatherData.current.wind_kph | number :
                                        '1.0-2'}}</b></span>
                            </div>
                            <div>
                                <span style="font-size: 13px;">Feels Like <b>{{weatherData.current.feelslike_c | number :
                                        '1.0-2'}}&deg;</b></span>
                            </div>
                        </div>

                    </div>
                </mat-card>
                <mat-card class="col-md-4 ml-auto" style="margin:5px 0px;padding:5px 10px 0px 10px;border-bottom: 2px solid #676cba;">
                    <div *ngIf="stockData.length>0">
                        <div *ngFor="let i of stockData | paginate: { itemsPerPage: 1, currentPage: p1, id: 'stock' }">
                            <!-- <div class="stockheader">Stock Market - Nifty 50 <span style="font-size: 11px;color: gray;">{{i.lastUpdateTime}}</span></div> -->
                            <div fxLayout="column">
                                <div style="font-size: 14px;font-weight: 500;color: gray;padding: 0px 0px 5px 0px;">
                                    {{i.symbol}}<span
                                        style="font-size: 14px;color: gray;float:right;">{{i.lastUpdateTime}}</span>
                                </div>
                                <div *ngIf="i.change>=0"
                                    style="font-size: 19px;font-weight: 500;padding: 0px 0px 5px 0px;color: green;">
                                    <span style="font-size: 19px;">{{i.lastPrice | number : '1.0-1'}}</span> <span
                                        style="float:right;">+{{i.change| number : '1.0-1'}} (+{{i.pChange| number :
                                        '1.0-1'}}%)</span></div>
                                <div *ngIf="i.change<0"
                                    style="font-size: 19px;font-weight: 500;padding: 0px 0px 5px 0px;color: red;"><span
                                        style="font-size: 19px;">{{i.lastPrice | number : '1.0-1'}}</span><span
                                        style="float:right;">{{i.change| number : '1.0-1'}} ({{i.pChange| number :
                                        '1.0-1'}}%)</span></div>
                                <div style="padding: 0px 0px 5px 0px;">
                                    <div><span style="font-size: 14px;color: gray;">Open: </span>{{i.open| number :
                                        '1.0-1'}} <span style="font-size: 14px;color: gray;margin-left:15px;">Prev.
                                            Close: </span>{{i.lastPrice| number : '1.0-1'}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="float:right;">
                            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                            <pagination-controls class="my-pagination" (pageChange)="p1 = $event" id="stock"
                                directionLinks="true" autoHide="true" responsive="true" previousLabel="" nextLabel=""
                                maxSize="7">
                            </pagination-controls>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="col-md-4 ml-auto" style="margin:5px 0px;padding:5px 10px;border-bottom: 2px solid #676cba;">
                    <div fxFlex="100%" *ngIf="panchangData && widgetBool">
                            <div style="font-size: 19px;font-weight: 600;margin-top: 10px;text-align: center;">ఈరోజు పంచాంగం</div>
                            <!-- <div><img src="https://buruju.com/images/panchang.png" width="35" height="35"></div> -->
                            <hr style="padding:5px;">
                        <div fxLayout="row">
                            <div><img src="https://buruju.com/images/panchang.png" width="75" height="75"></div>
                        <div>
                            <table cellspacing="15">
                            <tr>
                                <th style="font-size: 16px;">Tithi</th>
                            </tr>
                            <tr>
                                <td><div *ngFor="let i of panchangData.data.tithi">
                                   <div style="font-weight:600;font-size:14px">{{i.name}}</div> 
                                    <b>Start:</b> {{getTime(i.start)}} &nbsp;&nbsp;<b>End:</b> {{getTime(i.end)}}
                                </div></td>
                            </tr>
                        </table>
                    </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlex="100%" *ngIf="vaccineDataState.length>0 && !widgetBool">
                        <div fxLayout="column" fxLayoutAlign="space-between space-between">
                            <div class="vaccineheader" fxLayout="row">
                                <div style="text-align: center;">Total Vaccinated <mat-icon
                                        style="font-size: 16px;cursor: pointer;" matTooltip="Cosolidated of 28 States">
                                        info
                                    </mat-icon><br>
                                    <span class="vaccinedata">{{vaccinedata.total | number}}</span>
                                </div>
                                <div>
                                    <div style="margin-left: 15px;">Dose 1:
                                        <br>
                                        <span style="font-size: 14px;">{{vaccinedata.tot_dose_1 | number}}</span>
                                    </div>
                                </div>
                                <div>
                                    <div style="margin-left: 15px;">
                                        Dose 2:
                                        <br>
                                        <span style="font-size: 14px;">{{vaccinedata.tot_dose_2 | number}}</span>
                                    </div>
                                </div>
                            </div>
                            <mat-divider style="margin:2px;" [inset]="true"></mat-divider>
                            <div fxLayout="row">
                                <div fxLayout="column"
                                    style="margin:3px 5px;padding-right: 5px;text-align: center;margin-left: 15px;">
                                    <div class="vaccineheader">{{vaccineDataState[0].title}}</div>
                                    <div class="vaccinedataDose">{{vaccineDataState[0].totally_vaccinated | number}}
                                    </div>
                                </div>
                                <div fxLayout="column" style="margin:3px 5px;padding-right: 5px;text-align: center;">
                                    <div class="vaccineheader">{{vaccineDataState[1].title}}</div>
                                    <div class="vaccinedataDose">{{vaccineDataState[1].totally_vaccinated | number}}
                                    </div>
                                </div>
                                <div fxLayout="column" style="margin:3px 5px;padding-right: 5px;text-align: center;">
                                    <div class="vaccineheader">{{vaccineDataState[2].title}}</div>
                                    <div class="vaccinedataDose">{{vaccineDataState[2].totally_vaccinated | number}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <div class="col-md-7 ml-auto" style="padding: 2px;font-size:15px;">
                    <div class="card page-carousel" style="margin-bottom: 1px;">
                        <ngb-carousel>
                            <ng-template *ngFor="let i of resdataCarosel" ngbSlide>
                                <a [routerLink]="['/news', i.article_url]">
                                    <img src="{{i.image}}" alt="{{i.articletitle}}" style="background-color: #ffffff;
                            opacity: 1;border-radius: 0px !important;">
                                    <div class="carousel-caption" style="background-color: black;
                                width: 100%;right: 0%;left: 0%;bottom: 0px;opacity: 0.8;padding: 16px;">
                                        <h1>
                                            <span style=" font-weight: bold;color: #ffffff;font-size: 40px;">
                                                <span class="material-icons"
                                                    style="color:red;background: white;border-radius: 50%;width: 25px;height: 25px;margin-right:3px;">
                                                    trending_ups
                                                </span>
                                                <span *ngIf="i.articletitle.length<=30">{{i.articletitle |
                                                    titlecase}}</span>
                                                <span *ngIf="i.articletitle.length>30">{{i.articletitle | titlecase |
                                                    slice:0:30}}...</span>
                                            </span>
                                        </h1>
                                    </div>
                                </a>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                    <!-- <div>
                        <mat-card> -->
                            <!-- <table class="col"
                            style="font-size: 15px;font-weight:900;width:100%;padding:10px;text-align: center;">
                            <tr>
                                <th rowspan="3" style="padding:5px;">కరోనావైరస్: <br> ప్రపంచం</th>
                                <td>
                                    <span style="color:black;">ధృవీకరించిన</span>
                                </td>
                                <td>
                                    <span style="color:green;">కోలుకున్న</span>
                                </td>
                                <td>
                                    <span style="color:red;">మరణాల</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style="font-size:16px;color:black;">{{TotalConfirmed | number}}</span>
                                </td>
                                <td>
                                    <span style="font-size:16px;color:green;">{{TotalRecovered | number}}</span>
                                </td>
                                <td>
                                    <span style="font-size:16px;color:red;">{{TotalDeaths | number}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span
                                        style="font-size:15px;color:red;display: flex;align-items: center;margin-left: 2em;">
                                        <span class="material-icons">arrow_drop_up</span>
                                        {{NewConfirmed | number}}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        style="font-size:15px;color:green;display: flex;align-items: center;margin-left: 2em;"><span
                                            class="material-icons">arrow_drop_up</span>
                                        {{NewDeaths | number}}</span>
                                </td>
                                <td>
                                    <span
                                        style="font-size:15px;color:red;display: flex;align-items: center;margin-left: 2em;"><span
                                            class="material-icons">arrow_drop_up</span>
                                        {{NewRecovered | number}}</span>
                                </td>
                            </tr>
                        </table> -->
                            <!-- <table class="col"
                                style="font-size: 15px;font-weight:900;width:100%;padding:10px;text-align: center;">
                                <tr>
                                    <th rowspan="3" style="padding:5px;">కరోనావైరస్: <br>ఇండియా</th>
                                    <td>
                                        <span style="color:black;">ధృవీకరించిన</span>
                                    </td>
                                    <td>
                                        <span style="color:green;">కోలుకున్న</span>
                                    </td>
                                    <td>
                                        <span style="color:red;">మరణాల</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span style="font-size:16px;color:black;">{{TotalConfirmed | number}}</span>
                                    </td>
                                    <td>
                                        <span style="font-size:16px;color:green;">{{TotalRecovered | number}}</span>
                                    </td>
                                    <td>
                                        <span style="font-size:16px;color:red;">{{TotalDeaths | number}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span
                                            style="font-size:15px;color:red;display: flex;align-items: center;margin-left: 2em;">
                                            <span class="material-icons">arrow_drop_up</span>
                                            {{NewConfirmed | number}}
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            style="font-size:15px;color:green;display: flex;align-items: center;margin-left: 2em;"><span
                                                class="material-icons">arrow_drop_up</span>
                                            {{NewDeaths | number}}</span>
                                    </td>
                                    <td>
                                        <span
                                            style="font-size:15px;color:red;display: flex;align-items: center;margin-left: 2em;"><span
                                                class="material-icons">arrow_drop_up</span>
                                            {{NewRecovered | number}}</span>
                                    </td>
                                </tr>
                            </table>
                        </mat-card>
                    </div> -->
                </div>
                <!-- <div style="width: 100%;" id="bannerMobile">
                    <img  src="https://i.pinimg.com/736x/5f/64/7b/5f647be5aa6f6d54448dbd33410a729d.jpg"
                alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
            margin-left: auto;
            margin-right: auto;">
            </div> -->
                <div class="col-md-5 mr-auto " style="font-size:15px;padding: 0px;height:405px;overflow-x: auto;">
                    <div fxFlex="100%">
                        <div style="border-bottom: 1px solid lightgray;margin-top: 5px;">
                            <!-- <mat-icon class="example-tab-icon" style="vertical-align: middle;">local_movies</mat-icon>&nbsp; -->
                            <span style="font-size: 21px;font-weight: 600;">చలన చిత్రం</span></div>
                        <div *ngFor="let i of resdata_chalanachitram" fxLayout="row" fxLayoutAlign="flex-start" style="padding:5px 5px 0px 2px;">
                            <!-- <div > -->
                                <div  style="cursor: pointer;" [routerLink]="['/news', i.article_url]">
                                    <img src="{{i.image}}" alt="{{i.articletitle}}" width="110" height="110"/>
                                </div>
                                <div style="margin-left:5px">
                                    <a [routerLink]="['/news', i.article_url]"
                                        style="color:black;text-decoration: none;font-weight: 600;">{{i.articletitle | slice:0:100}}
                                        <div><span style="font-weight: 500;">{{i.articlecontent | slice:0:110}}...</span></div></a>
                                    <!-- <div class="icon-text"
                                        style="font-size: 12px;bottom: 8px;margin-top:35px;float:right;position: absolute;">
                                        <span class="material-icons" style="font-size: 17px;">person</span>
                                        <span style="padding:2px;">{{i.author}}</span>
                                        <span class="material-icons" style="margin-left:8px;font-size: 17px;">date_range</span>
                                        <span style="padding:8px;">{{i.date}}</span>
                                    </div> -->
                                </div>
                            <!-- </div> -->
                            <!-- <mat-divider [inset]="true"></mat-divider> -->
                        </div>
                        </div>
                        <mat-tab-group mat-stretch-tabs *ngIf="false">
                            <!-- <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon ">coronavirus</mat-icon>&nbsp;
                                ఎక్కడ మార్చాలి
                            </ng-template>
                            <div *ngFor="let i of resdata_ardikam | paginate: { itemsPerPage: 9, currentPage: p }">
                                <div fxLayout="row" fxLayoutAlign="flex-start" style="padding:5px 5px 0px 2px;">
                                    <div>
                                        <img src="{{i.image}}" alt="{{i.articletitle}}" width="110" height="110">
                                    </div>
                                    <div style="margin:5px">
                                        <a [routerLink]="['/news', i.article_url]"
                                            style="color:black;text-decoration: none;font-size: 18px;">{{i.articletitle}}</a>
                                        <div class="icon-text"
                                            style="font-size: 12px;bottom: 8px;margin-top:35px;float:right;position: absolute;">
                                            <span class="material-icons" style="font-size: 17px;">person</span><span
                                                style="padding:2px;">{{i.author}}</span>
                                            <span class="material-icons"
                                                style="margin-left:8px;font-size: 17px;">date_range</span>
                                            <span style="padding:8px;">{{i.date}}</span>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider [inset]="true"></mat-divider>
                            </div>

                        </mat-tab> -->
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon">local_movies</mat-icon>&nbsp;
                                చలన చిత్రం
                            </ng-template>
                            <div>
                            <!-- <div *ngFor="let i of resdata_reporterdairy | paginate: { itemsPerPage: 9, currentPage: p8, id:'eporterdairy '  }"> -->
                            <div *ngFor="let i of resdata_chalanachitram" fxLayout="row" fxLayoutAlign="flex-start" style="padding:5px 5px 0px 2px;">
                                <!-- <div > -->
                                    <div>
                                        <img src="{{i.image}}" alt="{{i.articletitle}}" width="110" height="110"/>
                                    </div>
                                    <div style="margin-left:5px">
                                        <a [routerLink]="['/news', i.article_url]"
                                            style="color:black;text-decoration: none;font-weight: 600;">{{i.articletitle | slice:0:100}}
                                            <div><span style="font-weight: 500;">{{i.articlecontent | slice:0:110}}...</span></div></a>
                                        <!-- <div class="icon-text"
                                            style="font-size: 12px;bottom: 8px;margin-top:35px;float:right;position: absolute;">
                                            <span class="material-icons" style="font-size: 17px;">person</span>
                                            <span style="padding:2px;">{{i.author}}</span>
                                            <span class="material-icons" style="margin-left:8px;font-size: 17px;">date_range</span>
                                            <span style="padding:8px;">{{i.date}}</span>
                                        </div> -->
                                    </div>
                                <!-- </div> -->
                                <!-- <mat-divider [inset]="true"></mat-divider> -->
                            </div>
                            </div>
                            <mat-divider></mat-divider>
                            <!-- <div style="text-align: right;padding-top: 2px;">
                                <pagination-controls (pageChange)="p9 = $event" id="chalanachitram" directionLinks="true"
                                autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                            </pagination-controls>
                            </div> -->
                        </mat-tab>
                            <!-- <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon class="example-tab-icon">menu_book</mat-icon>&nbsp;
                                    రిపోర్టర్ డైరీ
                                </ng-template>
                                <div style="overflow-x: scroll;">
                                <div
                                    *ngFor="let i of resdata_reporterdairy | paginate: { itemsPerPage: 9, currentPage: p8, id:'eporterdairy '  }" style="overflow-x: scroll;">
                                    <div fxLayout="row" fxLayoutAlign="flex-start" style="padding:5px 5px 0px 2px;">
                                        <div>
                                            <img src="{{i.image}}" alt="{{i.articletitle}}" width="110" height="110">
                                        </div>
                                        <div style="margin-left:5px">
                                            <a [routerLink]="['/news', i.article_url]"
                                            style="color:black;text-decoration: none;font-weight: 600;">{{i.articletitle | slice:0:100}}
                                            <div><span style="font-weight: 500;">{{i.articlecontent | slice:0:110}}...</span></div></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <mat-divider></mat-divider>
                                <div style="text-align: right;padding-top: 2px;">
                                    <pagination-controls (pageChange)="p8 = $event" id="eporterdairy " directionLinks="true"
                                    autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                                </pagination-controls>
                                </div>
                            </mat-tab> -->
                        </mat-tab-group>
                    </div>
               
            </div>
        </div>
        <!-- <div style="margin-top: 4px;">
            <div id="bannerFull">
        <img  src="https://thumbs.gfycat.com/AnchoredPointedBluebird-size_restricted.gif"
        alt="photo of buruju.com" style="width: 100%;max-height: 120px;padding: 2px;
margin-left: auto;
margin-right: auto;">
</div>
<div style="width: 100%;" id="bannerMobile">
    <img  src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
        alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
    margin-left: auto;
    margin-right: auto;">
    </div>
    </div> -->

        <div class="main">
            <app-basicelements></app-basicelements>
        </div>
        <div id="bannerFull" >
            <!-- <img src="https://www.yogiapproved.com/wp-content/uploads/2017/11/YOGA-STRONG-BANNER-900X250.jpg"
                alt="photo of buruju.com" style="width: 100%;height: auto; display: block;
        margin-left: auto;
        margin-right: auto;padding:0px 0px 20px 0px;"> -->
        <ng-adsense
        [adClient]="'ca-pub-6715553274893209'"
        [adSlot]="3991352689"
        [display]="'inline-block'"
        [width]="900"
        [height]="250"
      ></ng-adsense>
        </div>
        <div id="bannerMobile">
            <!-- <img  src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
            alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
          margin-left: auto;
          margin-right: auto;"> -->
          <ng-adsense
          [adClient]="'ca-pub-6715553274893209'"
          [adSlot]="3991352689"
          [display]="'inline-block'"
          [width]="300"
          [height]="250"
        ></ng-adsense>
          </div>
        <!-- <div id="bannerFull">
        <img  src="https://www.animatedbanner.com/images/samples/leader/bumpbaby728x90.gif"
        alt="photo of buruju.com" style="width: 100%;max-height: 120px;padding:2px;
margin-left: auto;
margin-right: auto;">
</div>
<div id="bannerMobile">
        <img  src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
        alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
    margin-left: auto;
    margin-right: auto;">
    </div> -->
    </div>
</div>
<div>
    <!--160x600 images-->
    <!-- <div id="FloatAdx-left">
        <img src="https://i.gifer.com/G80Q.gif">
    </div> -->
    <!-- <div id="FloatAdx-right">
        <img src="https://www.animatedbanner.com/images/samples/sky/apnea160x600.gif">
    </div> -->
    <div id="FloatAdx-left">
        <ng-adsense
        [adClient]="'ca-pub-6715553274893209'"
        [adSlot]="3991352689"
        [display]="'inline-block'"
        [width]="160"
        [height]="600"
      ></ng-adsense>
    </div>
    <div id="FloatAdx-right" >
        <ng-adsense
        [adClient]="'ca-pub-6715553274893209'"
        [adSlot]="3991352689"
        [display]="'inline-block'"
        [width]="160"
        [height]="600"
      ></ng-adsense>
    </div>
</div>