import { Component, OnInit, Renderer2, ViewChild,Inject } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NewsServiceService } from '../news-service.service';
import { News } from '../news';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// import * as postscribe from 'postscribe';
import { DOCUMENT } from '@angular/common';



@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.css']
})

export class ComponentsComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    page = 4;
    page1 = 5;
    focus;
    focus1;
    focus2;
    date: { year: number, month: number };
    model: NgbDateStruct;
    resdataCarosel;
    title = 'myHighchart';
    TotalConfirmed = 0;
    NewConfirmed = 0;
    TotalDeaths = 0;
    NewDeaths = 0;
    TotalRecovered = 0;
    NewRecovered = 0;
    virusData = [];


    resdata_ardikam;
    resdata_reporterdairy;
    resdata_chalanachitram;
    p1: any;
    p8: any;
    p9: any;
    weatherData;
    location;
    vaccinedata;
    vaccineDataState = [];
    stockData = [];
    todaydate;
    panchangData;
    widgetBool = false;
    tokenData;
    constructor(     private _renderer2: Renderer2, 
        @Inject(DOCUMENT) private _document: Document,public NewsServiceService: NewsServiceService, private renderer: Renderer2) { }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    ngOnInit() {
        this.todaydate = new Date();
        this.NewsServiceService.getLocation();
        this.NewsServiceService.getcoronadata().subscribe(resdata => {
            this.virusData = resdata;
            var newdata = resdata.hasOwnProperty("Countries");
            for (var i = 0; i < this.virusData["Countries"].length; i++) {
                if (this.virusData["Countries"][i].CountryCode == 'IN') {

                    var coronadata = this.virusData["Countries"][i];
                    this.TotalConfirmed = coronadata.TotalConfirmed;
                    this.NewConfirmed = coronadata.NewConfirmed;

                    this.TotalDeaths = coronadata.TotalDeaths;
                    this.NewDeaths = coronadata.NewDeaths;

                    this.TotalRecovered = coronadata.TotalRecovered;
                    this.NewRecovered = coronadata.NewRecovered;
                }
            }
        },error => {console.log('Something went wrong!', error)});
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function () {
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function () {
                input_group[i].classList.remove('input-group-focus');
            });
        }
        this.NewsServiceService.getNewsCarosel().subscribe((data: News[]) => {
            var newdata = JSON.stringify(data);
            this.resdataCarosel = data;
        },error => {console.log('Something went wrong!', error)});
        this.NewsServiceService.getNews_ardikam().subscribe((data: News[]) => {
            this.resdata_ardikam = data;
        },error => {console.log('Something went wrong!', error)});
        this.NewsServiceService.getNews_reporterdairy().subscribe((data: News[]) => {
            this.resdata_reporterdairy = data;
        },error => {console.log('Something went wrong!', error)});
        this.NewsServiceService.getNews_chalanachitram().subscribe((data: News[]) => {
            this.resdata_chalanachitram = data;
        },error => {console.log('Something went wrong!', error)});
        this.location = this.NewsServiceService.getLocation();
        // console.log(this.location.lat+" and "+this.location.lng);
        this.NewsServiceService.getWeather(this.location.lat, this.location.lng).subscribe(resdata => {
            this.weatherData = resdata;
            // console.log(JSON.stringify(this.weatherData));
        },error => {console.log('Something went wrong!', error)});
        this.NewsServiceService.getVaccineData().subscribe(vaccineData => {
            for(var i=0;i<vaccineData.getBeneficiariesGroupBy.length;i++){
                if(vaccineData.getBeneficiariesGroupBy[i].state_id=='2' || vaccineData.getBeneficiariesGroupBy[i].state_id=='21' || vaccineData.getBeneficiariesGroupBy[i].state_id=='32')
                    this.vaccineDataState.push(vaccineData.getBeneficiariesGroupBy[i]);
            }
            this.vaccinedata = vaccineData.topBlock.vaccination;
            // console.log(JSON.stringify(this.vaccinedata));
        },error => {console.log('Something went wrong!', error)});
        var indices1 = "NIFTY%20BANK";
        var indices2 = "NIFTY%2050";
        this.NewsServiceService.getStocksData(indices1).subscribe(stockData => {
            this.stockData = stockData;
            
        },error => {console.log('Something went wrong!', error)});
        // this.panchangData=this.NewsServiceService.getpanchangResData();
        // if(this.panchangData==null || this.panchangData==""){
        //     this.NewsServiceService.getPanchangToken().subscribe(res1=>{
        //         this.tokenData = res1;
        //         this.NewsServiceService.getPanchang(this.tokenData.access_token).subscribe(res2=>{
        //             this.panchangData = res2;
        //             this.NewsServiceService.setpanchangResData(this.panchangData);
        //             console.log(JSON.stringify(res2));
        //         },  
        //         error => {console.log('Something went wrong!', error)
        //     });
        //         },  
        //         error => {console.log('Something went wrong!', error)
        //     });
        // }
        // this.widgetBool = this.widgetBoolCall();
        // this.invokeAmazon();
    }
    invokeAmazon(){
        let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `
            {
                "@context": "https://schema.org"
                /* your schema.org microdata goes here */
            }
        `;
        this._renderer2.appendChild(this._document.body, script);
    }
    getTime(val){
        var fields = val.split('T');
        var finalField = fields[1].split('+');
        return finalField[0];
        // return val.split('+')[0];
    }
    widgetBoolCall(){
        var num = Math.floor(Math.random() * 100);
        for(var i = 2; i < num; i++)
        if(num % i === 0) return false;
      return num > 1;
    }
    // ngAfterViewInit() {
    //     postscribe('#bannerIdLeft', `<script type="text/javascript" language="javascript">
    //     var aax_size='160x600';
    //     var aax_pubname = 'buruju-21';
    //     var aax_src='302';
    //   </script>
    //   <script type="text/javascript" language="javascript" src="http://c.amazon-adsystem.com/aax2/assoc.js"></script>`);
    //     }
}
