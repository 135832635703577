import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NewsServiceService } from '../../news-service.service';
import { News } from '../../news';
@Component({
  selector: 'app-basicelements',
  templateUrl: './basicelements.component.html',
  styleUrls: ['./basicelements.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicelementsComponent implements OnInit {
  simpleSlider = 40;
  doubleSlider = [20, 60];
  state_default: boolean = true;
  focus: any;
  articlename = 'doggy';

  resdata_avievi;
  resdata_samajikam;
  resdata_ardikam;
  resdata_charitrakam;
  resdata_udhyogam;
  resdata_graminam;
  resdata_allNews;
  resdataCarosel;
  searchText;
  p2: any;
  p3: any;
  p4: any;
  p5: any;
  p6: any;
  p7: any;
  p20: any;
  finalNewsData;
  constructor(public NewsServiceService: NewsServiceService, public Router: Router) { }

  ngOnInit() {
    this.NewsServiceService.getNews_allNews().subscribe((data: News[]) => {
        this.resdata_allNews = data;
        for(var i=0;i<this.resdata_allNews.length;i++ ){
          var imageArr = [];
          imageArr.push(this.resdata_allNews[i].image);
          if(this.resdata_allNews[i].image1!=''&& this.resdata_allNews[i].image1!=null )
            imageArr.push(this.resdata_allNews[i].image1);
          if(this.resdata_allNews[i].image2!=''&& this.resdata_allNews[i].image2!=null )
            imageArr.push(this.resdata_allNews[i].image2);
          if(this.resdata_allNews[i].image3!=''&& this.resdata_allNews[i].image3!=null )
            imageArr.push(this.resdata_allNews[i].image3);
          this.resdata_allNews[i]["caroselData"] = imageArr;
        }
        this.finalNewsData = this.resdata_allNews;
      });
    // this.NewsServiceService.getNews_avievi().subscribe((data: News[]) => {
    //   this.resdata_avievi = data;
    // });
    // this.NewsServiceService.getNews_samajikam().subscribe((data: News[]) => {
    //   this.resdata_samajikam = data;
    // });
    // this.NewsServiceService.getNews_ardikam().subscribe((data: News[]) => {
    //   this.resdata_ardikam = data;
    // });
    // this.NewsServiceService.getNews_charitrakam().subscribe((data: News[]) => {
    //   this.resdata_charitrakam = data;
    // });
    // this.NewsServiceService.getNews_udhyogam().subscribe((data: News[]) => {
    //   this.resdata_udhyogam = data;
    // });
    // this.NewsServiceService.getNews_graminam().subscribe((data: News[]) => {
    //   this.resdata_graminam = data;
    // });
  }
  sharearticle(item, name) {
    var joinurl = item + name;
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (joinurl));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  getTagName(val){
    return this.NewsServiceService.getTagName(val);
}

}
