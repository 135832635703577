  <!-- Site footer -->
  <footer id="header" class="site-footer" style="color:white;">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h6>About</h6>
          <p>Buruju.com, the online telugu portal brings you weekly news and views mainly focusing on Social, Financial and Historical events of Andhra Pradesh and Telagana states from Hyderabad, India.</p>
        </div>

        <div class="col-xs-6 col-md-3">
          <h6>Contact</h6>
            For Editorial feedback and Marketing Contact:<Br>
              <a href="mailto:editor@buruju.com">editor@buruju.com</a>
        </div>

        <div class="col-xs-6 col-md-3">
          <h6>Quick Links</h6>
          <ul>
            <!-- <li><a href="https://buruju.com/">Home</a></li> -->
            <li><a href="https://buruju.com/#/about">About Us</a></li>
            <li><a href="https://buruju.com/#/contact">Contact Us</a></li>
            <li><a href="https://buruju.com/#/searchburuju">Search Buruju</a></li>
            <!-- <li><a href="https://buruju.com/#/privacypolicy">Privacy Policy</a></li> -->
          </ul>
        </div>
      </div>
      <hr>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-10 col-sm-6 col-xs-12">
          <p class="copyright-text">Copyright &copy; 2020 All Rights Reserved by 
       <a href="https://buruju.com">Buruju</a>. Contents of ‘Buruju.com’ are copyright protected. Copy or reproduction or re use of contents or any part thereof is illegal. Such persons will be prosecuted.</p>
        </div>

        <div class="col-md-2 col-sm-6 col-xs-12">
          <ul class="social-icons">
            <!-- <li><a class="facebook" style="color:#3b5998;" href="#"><i class="fa fa-facebook"></i></a></li> -->
            <li><a class="twitter" style="color:#00aced;" href="https://twitter.com/BurujuNews"><i class="fa fa-twitter"></i></a></li> 
          </ul>
        </div>
      </div>
    </div>
</footer>