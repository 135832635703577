import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar} from '@angular/material/snack-bar';
import { NewsServiceService } from '../../news-service.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(public MatSnackBar: MatSnackBar, public NewsServiceService : NewsServiceService) { }
  username;
  email;
  textbox;

  ngOnInit() {
  }
  formsubmit(){
    if(this.username==""||this.email==""||this.textbox=="" || this.username==null || this.email==null || this.textbox==null){
      this.MatSnackBar.open("Please fill required fields", "OK",{
        duration:20000,
      });
    }
    else{
      var todaydate = new Date();
      var data = {
        "username": this.username,
        "email": this.email,
        "textbox": this.textbox,
        "date": todaydate
      }
      this.NewsServiceService.saveContactinformation(data).subscribe(res=>{
        this.MatSnackBar.open("Submitted Successfully", "OK",{
          duration:20000,
        });
      },error => {console.log('Something went wrong!', error)});
    }
    }
  }

