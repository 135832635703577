<div class="section section-buttons">
    <div>
        <h1 style="margin: 10px !important;"><b>వార్తలు</b></h1>
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="22px" fxLayoutAlign="flex-start" style="padding: 2px;">
            <mat-card *ngFor="let i of finalNewsData | paginate: { itemsPerPage: 27, currentPage: p20, id:'allNews' }"
                class="example-card card card-stats" fxFlex="0 1 calc(33.3% - 32px)"
                fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
                <span class="label label-primary" style="margin-right:5px;font-size: 16px;">{{getTagName(i.category)}}</span>                       

                <a [routerLink]="['/news', i.article_url]" style="color:black;">
                    
                    <ngb-carousel>
                        <ng-template *ngFor="let j of i.caroselData" ngbSlide>
                                <img src="{{j}}" alt="{{i.articletitle}}" style="background-color: #ffffff;
                        opacity: 1;border-radius: 0px !important;max-height: 200px;">
                        </ng-template>
                    </ngb-carousel>
                    <!-- <img mat-card-image style="max-height: 200px;" src="{{i.image}}" alt="{{i.articletitle}}"> -->
                    <mat-card-content style="padding:5px 0px 5px 0px;margin-bottom: 4px !important;">
                        <h1>
                            <h1 *ngIf="i.articletitle.length<=55"><b>{{i.articletitle}}</b></h1>
                            <h1 *ngIf="i.articletitle.length>55"><b>{{i.articletitle | slice:0:55}}...</b></h1>
                        </h1>
                        <span >{{i.articlecontent | slice:0:120}}...</span>
                    </mat-card-content>
                </a>
                <div style="margin-top:2px;position: absolute;bottom: 3px;">
                    <!--<mat-divider [inset]="true"></mat-divider>-->
                    <div class="icon-text" style="margin-top:2px;">
                        <span class="material-icons" style="font-size:17px;">person</span><span
                            style="padding:2px;font-size:12px;">{{i.author}}</span>
                        <span class="material-icons" style="margin-left:8px;font-size:17px;">date_range</span>
                        <span style="padding:8px;" style="font-size:12px;">{{i.date}}</span>
                        <span class="material-icons" style="margin-left:8px;" ngbPopover="URL Copied!"
                            placement="top" style="cursor: pointer;font-size:21px;margin-left:10px;"
                            (click)="sharearticle('https://buruju.com/news/',i.articlename)">share</span>
                            <!-- <span class="label label-primary" style="margin-right:5px;">{{getTagName(i.category)}}</span>                        -->
                         </div>
                </div>
            </mat-card>
        </div>
        <div style="text-align: right;">
            <pagination-controls (pageChange)="p20 = $event" id="allNews" directionLinks="true"
            autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
        </pagination-controls>
        </div>
        <mat-tab-group mat-align-tabs="end" *ngIf="false">
            <mat-tab>
                <ng-template mat-tab-label class="headerFont">
                    అవీ ఇవీ
                </ng-template>
                <br>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="22px" fxLayoutAlign="flex-start" style="padding: 2px;">
                    <mat-card *ngFor="let i of resdata_avievi | paginate: { itemsPerPage: 9, currentPage: p2, id:'avievi' }"
                        class="example-card card card-stats" fxFlex="0 1 calc(33.3% - 32px)"
                        fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
                        <a [routerLink]="['/news', i.article_url]" style="color:black;">
                            <img mat-card-image style="max-height: 200px;" src="{{i.image}}" alt="{{i.articletitle}}">
                            <mat-card-content style="padding:0px 0px 20px 0px;">
                                <h1>
                                    <h1 *ngIf="i.articletitle.length<=55">{{i.articletitle}}</h1>
                                    <h1 *ngIf="i.articletitle.length>55">{{i.articletitle | slice:0:55}}...</h1>
                                </h1>
                                <span >{{i.articlecontent | slice:0:75}}...</span>
                            </mat-card-content>
                        </a>
                        <div style="margin-top:2px;position: absolute;bottom: 8px;">
                            <div class="icon-text" style="margin-top:2px;">
                                <span class="material-icons" style="font-size:17px;">person</span><span
                                    style="padding:2px;font-size:12px;">{{i.author}}</span>
                                <span class="material-icons" style="margin-left:8px;font-size:17px;">date_range</span>
                                <span style="padding:8px;" style="font-size:12px;">{{i.date}}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div style="text-align: right;">
                    <pagination-controls (pageChange)="p2 = $event" id="avievi" directionLinks="true"
                    autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                </pagination-controls>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label class="headerFont">
                    సామాజికం
                </ng-template>
                <br>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="22px" fxLayoutAlign="flex-start">
                    <mat-card *ngFor="let i of resdata_samajikam | paginate: { itemsPerPage: 9, currentPage: p3, id:'samajikam' }"
                        class="example-card card card-stats" fxFlex="0 1 calc(33.3% - 32px)"
                        fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
                        <a [routerLink]="['/news', i.article_url]" style="color:black;">
                            <img mat-card-image style="max-height: 200px;" src="{{i.image}}" alt="{{i.articletitle}}">
                            <mat-card-content style="padding:0px 0px 20px 0px;">
                                <h1>
                                    <h1 *ngIf="i.articletitle.length<=55">{{i.articletitle}}</h1>
                                    <h1 *ngIf="i.articletitle.length>55">{{i.articletitle | slice:0:55}}...</h1>
                                </h1>
                                <span >{{i.articlecontent | slice:0:75}}...</span>
                            </mat-card-content>
                        </a>
                        <div style="margin-top:2px;position: absolute;bottom: 8px;">
                            <div class="icon-text" style="margin-top:2px;">
                                <span class="material-icons" style="font-size:17px;">person</span><span
                                    style="padding:2px;font-size:12px;">{{i.author}}</span>
                                <span class="material-icons" style="margin-left:8px;font-size:17px;">date_range</span>
                                <span style="padding:8px;" style="font-size:12px;">{{i.date}}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div style="text-align: right;">
                    <pagination-controls (pageChange)="p3 = $event" id="samajikam" directionLinks="true"
                    autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                </pagination-controls>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label class="headerFont">
                    ఆర్థికం
                </ng-template>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="22px" fxLayoutAlign="flex-start">
                    <mat-card *ngFor="let i of resdata_ardikam | paginate: { itemsPerPage: 9, currentPage: p4, id:'ardikam' }"
                        class="example-card card card-stats" fxFlex="0 1 calc(33.3% - 32px)"
                        fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
                        <a [routerLink]="['/news', i.article_url]" style="color:black;">
                            <img mat-card-image style="max-height: 200px;" src="{{i.image}}" alt="{{i.articletitle}}">
                            <mat-card-content style="padding:0px 0px 20px 0px;">
                                <h1>
                                    <h1 *ngIf="i.articletitle.length<=55">{{i.articletitle}}</h1>
                                    <h1 *ngIf="i.articletitle.length>55">{{i.articletitle | slice:0:55}}...</h1>
                                </h1>
                                <span >{{i.articlecontent | slice:0:75}}...</span>
                            </mat-card-content>
                        </a>
                        <div style="margin-top:2px;position: absolute;bottom: 8px;">
                            <div class="icon-text" style="margin-top:2px;">
                                <span class="material-icons" style="font-size:17px;">person</span><span
                                    style="padding:2px;font-size:12px;">{{i.author}}</span>
                                <span class="material-icons" style="margin-left:8px;font-size:17px;">date_range</span>
                                <span style="padding:8px;" style="font-size:12px;">{{i.date}}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div style="text-align: right;">
                    <pagination-controls (pageChange)="p4 = $event" id="ardikam" directionLinks="true"
                    autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                </pagination-controls>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label class="headerFont">
                    చారిత్రకం
                </ng-template>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="22px" fxLayoutAlign="flex-start">
                    <mat-card *ngFor="let i of resdata_charitrakam | paginate: { itemsPerPage: 9, currentPage: p5, id:'charithram' }"
                        class="example-card card card-stats" fxFlex="0 1 calc(33.3% - 32px)"
                        fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
                        <a [routerLink]="['/news', i.article_url]" style="color:black;">
                            <img mat-card-image style="max-height: 200px;" src="{{i.image}}" alt="{{i.articletitle}}">
                            <mat-card-content style="padding:0px 0px 20px 0px;">
                                <h1>
                                    <h1 *ngIf="i.articletitle.length<=55">{{i.articletitle}}</h1>
                                    <h1 *ngIf="i.articletitle.length>55">{{i.articletitle | slice:0:55}}...</h1>
                                </h1>
                                <span >{{i.articlecontent | slice:0:75}}...</span>
                            </mat-card-content>
                        </a>
                        <div style="margin-top:2px;position: absolute;bottom: 8px;">
                            <div class="icon-text" style="margin-top:2px;">
                                <span class="material-icons" style="font-size:17px;">person</span><span
                                    style="padding:2px;font-size:12px;">{{i.author}}</span>
                                <span class="material-icons" style="margin-left:8px;font-size:17px;">date_range</span>
                                <span style="padding:8px;" style="font-size:12px;">{{i.date}}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div style="text-align: right;">
                    <pagination-controls (pageChange)="p5 = $event" id="charithram" directionLinks="true"
                    autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                </pagination-controls>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label class="headerFont">
                    ఉద్యోగం
                </ng-template>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="22px" fxLayoutAlign="flex-start">
                    <mat-card *ngFor="let i of resdata_udhyogam | paginate: { itemsPerPage: 9, currentPage: p6, id:'udyogam' }"
                        class="example-card card card-stats" fxFlex="0 1 calc(33.3% - 32px)"
                        fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
                        <a [routerLink]="['/news', i.article_url]" style="color:black;">
                            <img mat-card-image style="max-height: 200px;" src="{{i.image}}" alt="{{i.articletitle}}">
                            <mat-card-content style="padding:0px 0px 20px 0px;">
                                <h1>
                                    <h1 *ngIf="i.articletitle.length<=55">{{i.articletitle}}</h1>
                                    <h1 *ngIf="i.articletitle.length>55">{{i.articletitle | slice:0:55}}...</h1>
                                </h1>
                                <span >{{i.articlecontent | slice:0:75}}...</span>
                            </mat-card-content>
                        </a>
                        <div style="margin-top:2px;position: absolute;bottom: 8px;">
                            <div class="icon-text" style="margin-top:2px;">
                                <span class="material-icons" style="font-size:17px;">person</span><span
                                    style="padding:2px;font-size:12px;">{{i.author}}</span>
                                <span class="material-icons" style="margin-left:8px;font-size:17px;">date_range</span>
                                <span style="padding:8px;" style="font-size:12px;">{{i.date}}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div style="text-align: right;">
                    <pagination-controls (pageChange)="p6 = $event" id="udyogam" directionLinks="true"
                    autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                </pagination-controls>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label class="headerFont">
                    గ్రామీణం
                </ng-template>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="22px" fxLayoutAlign="flex-start">
                    <mat-card *ngFor="let i of resdata_graminam | paginate: { itemsPerPage: 9, currentPage: p7, id:'graminam' }"
                        class="example-card card card-stats" fxFlex="0 1 calc(33.3% - 32px)"
                        fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
                        <a [routerLink]="['/news', i.article_url]" style="color:black;">
                            <img mat-card-image style="max-height: 200px;" src="{{i.image}}" alt="{{i.articletitle}}">
                            <mat-card-content style="padding:0px 0px 20px 0px;">
                                <h1>
                                    <h1 *ngIf="i.articletitle.length<=55">{{i.articletitle}}</h1>
                                    <h1 *ngIf="i.articletitle.length>55">{{i.articletitle | slice:0:55}}...</h1>
                                </h1>
                                <span >{{i.articlecontent | slice:0:75}}...</span>
                            </mat-card-content>
                        </a>
                        <div style="margin-top:2px;position: absolute;bottom: 8px;">
                            <div class="icon-text" style="margin-top:2px;">
                                <span class="material-icons" style="font-size:17px;">person</span><span
                                    style="padding:2px;font-size:12px;">{{i.author}}</span>
                                <span class="material-icons" style="margin-left:8px;font-size:17px;">date_range</span>
                                <span style="padding:8px;" style="font-size:12px;">{{i.date}}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div style="text-align: right;">
                    <pagination-controls (pageChange)="p7 = $event" id="graminam" directionLinks="true"
                    autoHide="true" responsive="true" previousLabel="" nextLabel="" maxSize="5">
                </pagination-controls>
                </div>
            </mat-tab>
        </mat-tab-group>
        <!-- <mat-divider style="padding:0px 0px 15px 0px;"></mat-divider> -->
        <!-- <div class="tim-title">

            <h2>Basic Components</h2>
        </div>
        <div id="buttons">
            <div class="tim-title">
                <h3>Buttons
                    <br />
                    <small>Pick your style</small>
                </h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-danger">Default</button>
                    <button type="button" class="btn btn-danger btn-round">Round</button>
                    <button type="button" class="btn btn-danger btn-round"><i class="fa fa-heart"></i> With
                        Icon</button>
                    <button type="button" class="btn btn-danger btn-just-icon"><i class="fa fa-heart"></i></button>
                    <button type="button" class="btn btn-danger btn-link">Simple</button>
                </div>
            </div>
            <div class="tim-title">
                <h3><small>Pick your size</small></h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-outline-danger btn-sm">Small</button>
                    <button type="button" class="btn btn-outline-danger">Regular</button>
                    <button type="button" class="btn btn-outline-danger btn-lg">Large</button>
                </div>
            </div>
            <div class="tim-title">
                <h3><small>Pick your color</small></h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-outline-default btn-round">Default</button><button
                        type="button" class="btn btn-outline-primary btn-round">Primary</button>
                    <button type="button" class="btn btn-outline-info btn-round">Info</button>
                    <button type="button" class="btn btn-outline-success btn-round">Success</button>
                    <button type="button" class="btn btn-outline-warning btn-round">Warning</button>
                    <button type="button" class="btn btn-outline-danger btn-round">Danger</button>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-default btn-round">Default</button>
                    <button type="button" class="btn btn-primary btn-round">Primary</button>
                    <button type="button" class="btn btn-info btn-round">Info</button>
                    <button type="button" class="btn btn-success btn-round">Success</button>
                    <button type="button" class="btn btn-warning btn-round">Warning</button>
                    <button type="button" class="btn btn-danger btn-round">Danger</button>
                </div>
            </div>
        </div>
        <br />
        <div class="tim-title">
            <h3>Links</h3>
        </div>
        <div class="row">
            <div class="col-md-8">
                <a href="#0" class="btn btn-link btn-default">Default</a>
                <a href="#0" class="btn btn-link btn-primary">Primary</a>
                <a href="#0" class="btn btn-link btn-success">Success</a>
                <a href="#0" class="btn btn-link btn-info">Info</a>
                <a href="#0" class="btn btn-link btn-warning">Warning</a>
                <a href="#0" class="btn btn-link btn-danger">Danger</a>
                <a href="#0" class="btn btn-link btn-neutral">Neutral</a>
            </div>
        </div>
        <br />
        <div class="tim-title">
            <h3>Inputs</h3>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Default">
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group has-success">
                    <input type="text" class="form-control form-control-success" id="inputSuccess1" value="Success">
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group has-danger">
                    <input type="text" class="form-control form-control-danger" id="inputDanger1" value="Error">
                    <div class="form-control-feedback">Sorry, that username's taken. Try another?</div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="input-group" [ngClass]="{'input-group-focus':focus===true}">
                    <input type="text" class="form-control" placeholder="Username" aria-describedby="basic-addon1"
                        (focus)="focus=true" (blur)="focus=false">
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-group"
                                aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="tim-title">
                    <h3>Checkboxes</h3>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="">
                        Unchecked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" checked="">
                        Checked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" disabled="">
                        Disabled unchecked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" checked="" disabled="">
                        Disabled checked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="tim-title">
                    <h3>Radio Buttons</h3>
                </div>
                <div class="form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                            value="option1">
                        Radio is off
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                            value="option2" checked="">
                        Radio is on
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check-radio disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios3"
                            value="option3" disabled="">
                        Disabled Radio is off
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check-radio disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadios1" id="exampleRadios4"
                            value="option3" checked disabled="">
                        Disabled Radio is on
                        <span class="form-check-sign"></span>
                    </label>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="tim-title">
                    <h3>Toggle Buttons</h3>
                </div>
                <bSwitch [switch-on-color]="'primary'">
                </bSwitch>
                <bSwitch [switch-on-color]="'primary'" [(ngModel)]="state_default"> Toggle is off
                </bSwitch>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="tim-title">
                    <h3>Sliders</h3>
                </div>
                <nouislider [connect]="false" [min]="0" [max]="100" [step]="1" [(ngModel)]="simpleSlider"
                    [tooltips]="false" class="slider"></nouislider>
                <br>
                <nouislider class="slider slider-info" [connect]="true" [min]="0" [max]="100" [step]="1"
                    [(ngModel)]="doubleSlider" [tooltips]="false"></nouislider>
            </div>
        </div> -->
    </div>
</div>