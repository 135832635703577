import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import CryptoJS from 'crypto-js/';
// declare var CryptoJS: any
// var CryptoJS = require("crypto-js");
@Injectable()
export class MyInterceptor implements HttpInterceptor {
  request = "ashok";
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var jsonval = this.getVal();
    // console.log(jsonval);
    const Authorization = this.encryptUsingSHA512("YnVydWp1b25saW5lbmV3c1BhcGVy", jsonval);
    return next.handle(httpRequest.clone({ setHeaders: { Authorization } }));
  }
  encryptUsingSHA512(passphrase, plain_text) {
    // var salt = CryptoJS.lib.WordArray.random(256);
    // var iv = CryptoJS.lib.WordArray.random(16);
    // var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });
    // var encrypted = CryptoJS.AES.encrypt(plain_text, key, { iv: iv });

    // var data = {
    //   ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
    //   salt: CryptoJS.enc.Hex.stringify(salt),
    //   iv: CryptoJS.enc.Hex.stringify(iv)
    // }
    return JSON.stringify("");
  }
  getVal(){
    var date1 = (new Date()).getTime().toString();
    // return date1.slice(0,8);
    var val = "XXGKYFQOP";
    return val;
  }
}