<div id="header" class="wrapper">
    <div class="filter"></div>
    <div class="section profile-content">
        <div class="container">
            <div style="margin-top:5em;"></div>
            <br />
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="12px" fxLayoutAlign="flex-start">
                <mat-card *ngIf="loaddata" style="padding:5px;" fxFlex="0 1 calc(75% - 0px)"
                    fxFlex.lt-md="0 1 calc(75% - 32px)" fxFlex.lt-sm="100%">
     
                    <mat-card-title style="padding:10px 0px;font-weight: 600;font-size: 27px;line-height: initial;">
                        {{resdata.articletitle}}
                    </mat-card-title>
                    <mat-divider [inset]="true"></mat-divider>
                    <div class="icon-text" style="margin-top:2px;color:gray;">
                        <span class="material-icons">person</span><span style="padding:8px;">{{resdata.author}} </span>
                        <span class="material-icons" style="margin-left:15px;">date_range</span>
                        <span style="padding:8px;">{{resdata.date}}</span>
                        <a rel="tooltip" title="Share on Whatsapp" data-placement="bottom" href="/"
                            target="_blank" style="padding: .5em .4em !important;">
                            <span style="color:#075e54">
                                <i style="font-size:1.5em;" class="fa fa-whatsapp"></i>
                            </span>
                        </a>
                        <!-- <a class="nav-link" rel="tooltip" title="Share on Twitter" data-placement="bottom" href="https://twitter.com/intent/tweet?url={{resdata.article_url}}&via=getboldify&text={{resdata.articletitle}}'" target="_blank"> -->

                        <a rel="tooltip" title="Share on Twitter" data-placement="bottom" href="http://twitter.com/share?text={{resdata.articletitle}}&url=https://buruju.com/news/{{resdata.article_url}}&image=https://buruju.com/images/buruju1.jpg&hashtags=buruju,burujutelugunews,{{resdata.category}}" target="_blank">
                            <span style="color:#00acee"  style="padding: .5em .4em !important;">
                                <i style="font-size:1.5em;" class="fa fa-twitter-square"></i>
                            </span>
                        </a>
                        <a rel="tooltip" title="Share on Twitter" data-placement="bottom" href="/"
                            target="_blank"  style="padding: .5em .4em !important;">
                            <span style="color:#3b5998">
                                <i style="font-size:1.5em;" class="fa fa-facebook-square"></i>
                            </span>
                        </a>
                        <a rel="tooltip" title="Share Link" ngbPopover="URL Copied!" placement="bottom"
                             style="padding: .5em .4em !important;cursor: pointer;">
                            <span style="color:black">
                                <i style="font-size:1.5em;" class="fa fa-share-square"></i>
                            </span>
                        </a>
                    </div>
                    <mat-divider [inset]="true"></mat-divider>
      
                    <mat-card-content>
                        <!-- <div>
                            <ins class="adsbygoogle"
                               style="display:block"
                               data-ad-client="ca-pub-6715553274893209"
                               data-ad-slot="3991352689"
                               data-ad-format="auto"
                               data-full-width-responsive="true"></ins>
                           </div> -->
                           <!-- <ng-adsense
  [adClient]="'ca-pub-6715553274893209'"
  [adSlot]="3991352689"
  [display]="'inline-block'"
  [width]="728"
  [height]="90"
></ng-adsense> -->
                        <img src="{{resdata.image}}" alt="{{resdata.image_caption1}" style="width: 100%;height: auto; display: block;
                        margin-left: auto;
                        margin-right: auto;padding:10px 0px;">
                        <span
                            style="display: block;
                         margin-left: auto;
                         margin-right: auto;padding:10px 0px;text-align:center;size:11px;"><b>{{resdata.image_caption1 | titlecase}}</b></span>
                        <h3>
                            <span class="justifytext">{{resdata.articlecontent}}</span>
                        </h3>
                        <span *ngIf="resdata.image2!='' ||resdata.image2!= null">
                            <img src="{{resdata.image2}}" alt="{{resdata.image_caption2}}" style="width: 100%;height: auto; display: block;
                    margin-left: auto;
                    margin-right: auto;padding:10px 0px;">
                            <span
                                style="display: block;
                     margin-left: auto;
                     margin-right: auto;padding:10px 0px;size:11px;text-align:center;size:11px;"><b>{{resdata.image_caption2 | titlecase}}</b></span>
                        </span>
                        <h3>
                            <span
                                *ngIf="resdata.articlecontent2!='' ||resdata.articlecontent2!= null" class="justifytext">{{resdata.articlecontent2}}</span>
                        </h3>
                        <div id="bannerFull" >
                            <!-- <img src="https://www.yogiapproved.com/wp-content/uploads/2017/11/YOGA-STRONG-BANNER-900X250.jpg"
                                alt="photo of buruju.com" style="width: 100%;height: auto; display: block;
                        margin-left: auto;
                        margin-right: auto;padding:0px 0px 20px 0px;"> -->
                        <ng-adsense
                        [adClient]="'ca-pub-6715553274893209'"
                        [adSlot]="3991352689"
                        [display]="'inline-block'"
                        [width]="728"
                        [height]="90"
                      ></ng-adsense>
                        </div>
                        <div id="bannerMobile">
                            <!-- <img  src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
                            alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
                          margin-left: auto;
                          margin-right: auto;"> -->
                          <ng-adsense
                          [adClient]="'ca-pub-6715553274893209'"
                          [adSlot]="3991352689"
                          [display]="'inline-block'"
                          [width]="300"
                          [height]="250"
                        ></ng-adsense>
                          </div>
                        <div id="bannerFull"   *ngIf="NewsServiceService.newsPageAds">
                            <img src="https://www.yogiapproved.com/wp-content/uploads/2017/11/YOGA-STRONG-BANNER-900X250.jpg"
                                alt="photo of buruju.com" style="width: 100%;height: auto; display: block;
                        margin-left: auto;
                        margin-right: auto;padding:0px 0px 20px 0px;">
                        </div>
                        <div id="bannerMobile"   *ngIf="NewsServiceService.newsPageAds">
                            <img  src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
                            alt="photo of buruju.com" style="width:100%;height: 100%;padding:2px;
                          margin-left: auto;
                          margin-right: auto;">
                          </div>
                        <span *ngIf="resdata.image3!='' ||resdata.image3!= null">
                            <img src="{{resdata.image3}}" alt="{{resdata.image_caption3}}" style="width: 100%;height: auto; display: block;
                    margin-left: auto;
                    margin-right: auto;padding:10px 0px;">
                            <span
                                style="display: block;
                     margin-left: auto;
                     margin-right: auto;padding:10px 0px;size:11px;text-align:center;size:11px;"><b>{{resdata.image_caption3 | titlecase}}</b></span>
                        </span>
                        <h3>
                            <span
                                *ngIf="resdata.articlecontent3!='' ||resdata.articlecontent3!= null"  class="justifytext">{{resdata.articlecontent3}}</span>
                        </h3>
                        <span *ngIf="resdata.image4!='' ||resdata.image4!= null">
                            <img src="{{resdata.image4}}" alt="{{resdata.image_caption4}}" style="width: 100%;height: auto; display: block;
                    margin-left: auto;
                    margin-right: auto;padding:10px 0px;">
                            <span
                                style="display: block;
                     margin-left: auto;
                     margin-right: auto;padding:10px 0px;size:11px;text-align:center;size:11px;"><b>{{resdata.image_caption4 | titlecase}}</b></span>
                        </span>
                        <h3>
                            <span
                                *ngIf="resdata.articlecontent4!='' ||resdata.articlecontent4!= null"  class="justifytext">{{resdata.articlecontent4}}</span>
                        </h3>
                    </mat-card-content>
                    
                    <div style="margin-bottom: -10px;position: absolute;bottom: 10px;width:100%;">
                        <div class="icon-text" style="margin-top:2px;">
                            <div>
                                <b style="margin-right:5px;">Tags:</b>
                                <!-- <span class="label label-default" style="margin-right:5px;">Default</span>-->
                                <span class="label label-primary" style="margin-right:5px;">{{getTagName(resdata.category)}}</span>
                                <span class="label label-info" style="margin-right:5px;">{{resdata.articletype}}</span>
                                <!-- <span class="label label-success" style="margin-right:5px;">Success</span>
                                <span class="label label-warning" style="margin-right:5px;">Warning</span>
                                <span class="label label-danger" style="margin-right:5px;">Danger</span> -->
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="example-card card card-stats" fxFlex="0 1 calc(25% - 12px)"
                    fxFlex.lt-md="0 1 calc(25% - 12px)" fxFlex.lt-sm="100%" style="padding:5px;">
                    <img  *ngIf="NewsServiceService.newsPageAds" mat-card-image style="padding:15px;"
                        src="https://www.disruptivestatic.com/wp-content/uploads/2015/03/AG-ever-336x280-300x250.png"
                        alt="photo of buruju.com">
                    <mat-card-content>
                        <h3>Recent Posts:</h3>
                        <mat-divider [inset]="true"></mat-divider>
                        <div *ngFor="let i of recentdata">
                            <div fxLayout="column" fxLayoutAlign="flex-start" style="padding:5px 5px 0px 2px;">
                                <div style="
                                margin-left: auto;
                                margin-right: auto;">
                                    <img src="{{i.image}}" alt="{{i.articletitle}}" class="recentimage">
                                </div>
                                <div style="margin-left:5px;padding-top: 5px;">
                                    <a [routerLink]="['/news', i.article_url]"
                                        style="color:black;text-decoration: none;font-size: 16px;font-weight: 500;line-height: 5px;"><b>{{i.articletitle}}</b><br>
                                        {{i.articlecontent | slice:0:75}}...
                                    </a>
                                    <div class="icon-text"
                                        style="font-size: 14px;">
                                        <span class="material-icons" style="font-size: 17px;"></span><span
                                            style="padding:5px;" class="label label-info">{{getTagName(i.category)}}</span>

                                    </div>
                                </div>
                            </div>
                            <mat-divider [inset]="true"></mat-divider>
                          </div>
                    </mat-card-content>
                    <span style="display: flex;
                    align-items: center;margin-top:15px;">
                        <img  *ngIf="NewsServiceService.newsPageAds" mat-card-image style="padding:15px;"
                            src="https://www.disruptivestatic.com/wp-content/uploads/2015/03/AG-ever-336x280-300x250.png"
                            alt="photo of buruju.com">
                    </span>
                </mat-card>
            </div>
        </div>
    </div>

</div>
<!-- <div style="margin: 0px 135px;">
<img id="bannerFull" src="https://www.animatedbanner.com/images/samples/leader/bumpbaby728x90.gif"
alt="photo of buruju.com" style="width: 100%;max-height: 120px;
margin-left: auto;
margin-right: auto;">
<img id="bannerMobile" src="https://i.pinimg.com/originals/a0/06/b1/a006b15484eecb840ff9dca9f2916660.jpg"
alt="photo of buruju.com" style="width:100%;height: 100%;
margin-left: auto;
margin-right: auto;">
</div> -->
<div>
    <!--160x600 images-->
    <!-- <div id="FloatAdx-left">
        <img src="https://i.gifer.com/G80Q.gif">
    </div>
    <div id="FloatAdx-right">
        <img src="https://www.animatedbanner.com/images/samples/sky/apnea160x600.gif">
    </div> -->
    <!-- <div id="FloatAdx-left" *ngIf="NewsServiceService.mainPageSideAds">
        <div id="bannerIdLeft"></div>
    </div>
    <div id="FloatAdx-right" *ngIf="NewsServiceService.mainPageSideAds">
        <div id="bannerIdLeft"></div>
    </div> -->
    <div id="FloatAdx-left">
        <ng-adsense
        [adClient]="'ca-pub-6715553274893209'"
        [adSlot]="3991352689"
        [display]="'inline-block'"
        [width]="160"
        [height]="600"
      ></ng-adsense>
    </div>
    <div id="FloatAdx-right" >
        <ng-adsense
        [adClient]="'ca-pub-6715553274893209'"
        [adSlot]="3991352689"
        [display]="'inline-block'"
        [width]="160"
        [height]="600"
      ></ng-adsense>
    </div>
</div>